// ExportManager.js

import { setTextControlsSettings } from './utility/textbox';
import
{
	stateManager,
	paperManager,
	canvas
} from '../autoload';

/**
 * ExportManager handles exporting the canvas in different formats.
 * 
 * The export formats include JSON and SVG, and it also supports 
 * saving the canvas to file. Furthermore, it can load a canvas 
 * from a given JSON data.
 * 
 * @class
 */
export class ExportManager
{
	/** 
	 * Create an ExportManager instance.
	 */
	constructor()
	{
		this.resetViewport = [...canvas.instance.viewportTransform];
		this.restoreviewport = null;
	}

	/**
	 * Exports the canvas in JSON format.
	 * @param {string} filename - The name of the exported file.
	 */
	exportCanvasToJSON(filename)
	{
		// Get all Objects
		const objs = canvas.instance.getObjects();
		for (let i = 0; i < objs.length; i++)
		{
			const obj = objs[i];

			// Skip Background Rect
			if (obj == paperManager.instance.canvas_rect)
			{
				continue;
			}

			// Remove object outside Background Rect
			// if (!this.canvasManager.canvas_rect.intersectsWithObject(obj))
			// {
			//This object is outside of the canvas
			// this.canvas.remove(obj);
			// }
		}

		const custom_json = canvas.instance.toJSON(['id']); // include id

		// Move to toolbar manager
		custom_json.canvas_size = document.getElementById('setPaper').value;

		const data = JSON.stringify(custom_json);
		const mimeType = 'application/json';
		this.exportCanvasData(filename, data, mimeType);
	}

	/**
	 * Exports the canvas in SVG format.
	 * @param {string} filename - The name of the exported file.
	 */
	exportCanvasToSVG(filename)
	{
		const svg = canvas.instance.toSVG();
		const mimeType = 'image/svg+xml';

		//Converting svg to dom element
		const parser = new DOMParser();
		const svgDoc = parser.parseFromString(svg, 'image/svg+xml');
		const svgElement = svgDoc.documentElement;

		//Getting canvas's top, left, width and height
		const { top, left, width, height } = paperManager.instance.getCanvasRectArea();

		//Setting svg's width and height
		svgElement.setAttribute('width', width);
		svgElement.setAttribute('height', height);
		svgElement.setAttribute('viewBox', `${left} ${top} ${width} ${height}`);

		//Set background rect to white
		const rect = svgElement.querySelector('rect');

		if (rect)
		{
			rect.setAttribute('fill', '#333333');
		}

		//Converting svg to string
		const serializer = new XMLSerializer();
		const modifiedSvg = serializer.serializeToString(svgElement);
		this.exportCanvasData(filename, modifiedSvg, mimeType);
	}

	/**
	 * Saves the current state of the canvas to a file.
	 * @param {string} type - The file format (e.g., 'jpg' or 'png').
	 * @param {number} quality - The quality of the saved image (between 0 and 1).
	 */
	saveCanvasToJPG(type, quality)
	{
		//Getting canvas's top, left, width and height
		const { top, left, width, height } = paperManager.instance.getCanvasRectArea();

		this.restoreviewport = [...canvas.instance.viewportTransform];
		canvas.instance.viewportTransform = [...this.resetViewport];

		// const zoom = canvas.getZoom();
		canvas.instance.zoomToPoint(new fabric.Point(canvas.instance.width / 2, canvas.instance.height / 2), 1);

		const dataURL = canvas.instance.toDataURL({
			format: type,
			quality: quality,
			backgroundColor: 'white', // Set the background color here
			left: left,
			top: top,
			width: width,
			height: height,
		});

		this.exportCanvasData(`canvas.${type}`, dataURL, null, true);

		//Revert zoom
		canvas.instance.viewportTransform = [...this.restoreviewport];
	}

	/**
	 * Loads a canvas from the provided JSON data.
	 * @param {string} jsonData - The JSON string representing the canvas.
	 * @param {boolean} importCase -  Flag to indicate whether the loaded data is for import (Default: true).
	 */
	loadCanvasFromJSON(jsonData, importCase = true, onComplete = null)
	{
		stateManager.instance.externalPendingTransaction=true;
		if (!jsonData.trim()) throw new Error("Error: Empty JSON Input.");

		try
		{
			const parsedData = JSON.parse(jsonData);

			if (importCase)
			{
				// This needs to be moved to the ToolbarManager
				const paperElement = document.getElementById('setPaper');
				paperElement.value = parsedData.canvas_size;
				paperManager.instance.setPaperAndZoom(parsedData.canvas_size);
			}
			
			// canvas.instance.clear();
			canvas.instance.loadFromJSON(parsedData, () =>
			{
				paperManager.instance.addCanvasRect();
				canvas.instance.getObjects().forEach(obj => setTextControlsSettings(obj));
				canvas.instance.renderAll();

				if (typeof onComplete === "function") onComplete();

				stateManager.instance.externalPendingTransaction=false;
			});
		}
		catch (error)
		{
			throw new Error("Invalid JSON Data: " + error);
		}
	}

	/**
	 * Creates a link to download the exported canvas data.
	 * @param {string} filename - The name of the exported file.
	 * @param {string} data - The exported data.
	 * @param {string} mimeType - The MIME type of the exported data.
	 * @param {boolean} isDataURL - True if data is a Data URL.
	 */
	exportCanvasData(filename, data, mimeType, isDataURL = false)
	{
		const link = document.createElement('a');
		link.href = isDataURL ? data : `data:${mimeType};charset=utf-8,` + encodeURIComponent(data);
		link.download = filename;
		link.click();
	}
}