export const canvasManager = { instance: null };
export const canvas = { instance: null };
export const logManager = { instance: null };
export const zoomManager = { instance: null };
export const toolTip = { instance: null };
export const toolbarManager = { instance: null };
export const stateManager = { instance: null };
export const selectionManager = { instance: null };
export const saveManager = { instance: null };
export const paperManager = { instance: null };
export const panningManager = { instance: null };
export const textboxOptions = { instance: null };
export const objectFactory = { instance: null };
export const objectEventsManager = { instance: null };
export const objectControls = { instance: null };
export const keyboardShortcuts = { instance: null };
export const exportManager = { instance: null };
export const objectOptions = { instance: null };

// No Dependancies
// export const alignGuidelines = { instance: null };
// export const scaleGuidelines = { instance: null };
// export const rotateGuidelines = { instance: null };