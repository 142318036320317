// /modules/objects/Textbox.js

import { fabric } from 'fabric';
import { canvas } from '../../autoload';
import { setTextControlsSettings } from '../utility/textbox';

class Textbox
{
	constructor(text)
	{
		this.textbox = new fabric.Textbox(text, {
			left: canvas.instance.width / 2,
			top: canvas.instance.height / 2,
			fill: '#000000',
			fontSize: 30,
			width: 200,
			textAlign: 'center',
		});
		setTextControlsSettings(this.textbox);
	}

	getObject()
	{
		return this.textbox;
	}
}

export default Textbox;