// .modules/objects/Triangle.js

import { fabric } from 'fabric';
import { canvas } from '../../autoload';

class Circle
{
	constructor()
	{
		this.triangle = new fabric.Circle({
			left: canvas.instance.width / 2,
			top: canvas.instance.height / 2,
			fill: '#D9D9D9',
			radius: 100
		});
	}

	getObject()
	{
		return this.triangle;
	}
}

export default Circle;