import { fabric } from 'fabric';
import { canvas } from '../../autoload';

class Diamond
{
	constructor()
	{
		this.diamond = new fabric.Polygon([
			{ x: 75, y: 0 },
			{ x: 150, y: 100 },
			{ x: 75, y: 200 },
			{ x: 0, y: 100 }], {
			left: canvas.instance.width / 2,
			top: canvas.instance.height / 2,
			fill: '#D9D9D9'
		});
	}

	getObject()
	{
		return this.diamond;
	}
}

export default Diamond;
