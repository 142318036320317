// CanvasManager.js

// Modules
import { fabric } from 'fabric';
import { AlignGuidelines } from './AlignGuidelines';
import { ScaleGuidelines } from './ScaleGuidelines';
import { RotateGuidelines } from './RotateGuidelines';
import { keyboardShortcuts, canvas } from '../autoload';

// Utility
import { canvasConstants } from './utility/CanvasConstants';

/**
 * Handles the overall operations of the canvas.
 * It sets up the main canvas and associated managers for various features. 
 * This is where we initialize and define the canvas and its functionalities.
 * @class
 */

export class CanvasManager
{
	/** 
	* @constructs CanvasManager
	* @param {string} canvasId - Identifier for the canvas.
	* @param {string} canvasParentId - Identifier for the parent of the canvas.
	*/
	constructor(canvasId, canvasParentId)
	{
		this.initializeCanvas(canvasId, canvasParentId);
		this.initializeGuidelines();
		// this.initializeCanvasEvents();
	}

	/** 
	 * Initializes the main canvas.
	 * @param {string} canvasId - Identifier for the canvas.
	 * @param {string} canvasParentId - Identifier for the parent of the canvas.
	 */
	initializeCanvas(canvasId, canvasParentId)
	{
		const canvas_parent = document.getElementById(canvasParentId);

		canvas.instance = new fabric.Canvas(canvasId, {
			preserveObjectStacking: true,
			width: canvas_parent.clientWidth,
			height: canvas_parent.clientHeight,
			originX: 'center',
			originY: 'center',
			backgroundColor: '#fff',
			selectionColor: 'rgba(68, 154, 213, 0.2)',
			selectionBorderColor: 'rgba(68, 154, 213, 0.65)',
			selectionLineWidth: 1,
			// controlsAboveOverlay: true,
		});

		// Use CTRL to multi-select
		// canvas.instance.selectionKey = 'ctrlKey';

		//Set global attributes
		fabric.Object.prototype.lockSkewingX = canvasConstants.LOCK_SKEWING_X;
		fabric.Object.prototype.lockSkewingY = canvasConstants.LOCK_SKEWING_Y;
		fabric.Object.prototype.originX = 'center';
		fabric.Object.prototype.originY = 'center';
	}

	/**
	 * Initializes the canvas events.
	 */
	initializeGuidelines()
	{
		// AlignGuidelines
		const alignGuidelines = new AlignGuidelines({ canvas: canvas.instance, });
		alignGuidelines.init();

		// ScaleGuidelines
		const scaleGuidelines = new ScaleGuidelines({ canvas: canvas.instance, });
		scaleGuidelines.init();

		// RotateGuidelines
		const rotateGuidelines = new RotateGuidelines({ canvas: canvas.instance, });
		rotateGuidelines.init();
	}
	/**
	 * Initializes the canvas events.
	 */
	initializeCanvasEvents()
	{
		// Event listeners
		document.addEventListener('keydown', keyboardShortcuts.instance.onKeyDown.bind(keyboardShortcuts));
	}
}