// StateManager.js

import
{
	canvas,
	stateManager
} from '../autoload';

import { setTextControlsSettings } from './utility/textbox';

export class SelectionManager
{
	constructor()
	{
		this.lastPastePosition = null;
		this.pasteCount = 1;
		this.maxObjects = 50;
	}

	getActiveObjectId()
	{
		const activeObject = canvas.instance.getActiveObject();
		if (!activeObject) return null;

		let returnObject = activeObject.type === 'activeSelection'
			? activeObject.getObjects().map(({ id }) => id)
			: activeObject.id;

		return returnObject;
	}

	reselectObject(id)
	{
		if (!id) { return; }

		const objectsInCanvas = this.getSelectableObjects();

		Array.isArray(id)
			? this.selectMultipleObjects(id, objectsInCanvas)
			: this.selectSingleObject(id, objectsInCanvas);
	}

	selectMultipleObjects(ids, objectsInCanvas)
	{
		const objectsToSelect = ids.map(id => objectsInCanvas.find(o => o.id === id)).filter(Boolean);

		if (!objectsToSelect.length) { return; }

		const activeSelection = new fabric.ActiveSelection(objectsToSelect, { canvas: canvas.instance });
		canvas.instance.setActiveObject(activeSelection);
	}

	selectSingleObject(id, objectsInCanvas)
	{
		const object = objectsInCanvas.find(o => o.id === id);
		if (!object) { return; }

		canvas.instance.setActiveObject(object);
	}

	copyObject()
	{
		const activeObjects = canvas.instance.getActiveObjects();
		if (!activeObjects.length) return;

		const activeObject = canvas.instance.getActiveObject();

		activeObject?.clone((cloned) =>
		{
			this._clipboard = cloned;
		});
	}

	cutObject()
	{
		const activeObjects = canvas.instance.getActiveObjects();
		if (!activeObjects.length) return;

		const activeSelection = new fabric.ActiveSelection(activeObjects, { canvas: canvas.instance });

		activeSelection.clone((cloned) =>
		{
			this._clipboard = cloned;

			canvas.instance.discardActiveObject();
			activeObjects.forEach(object => canvas.instance.remove(object));
		});
	}

	pasteObject()
	{
		if (!this._clipboard) { return };
		if (!this.canAddObjects(this.getNumObjects(this._clipboard))) { return };

		this._clipboard.clone((clonedObj) =>
		{
			canvas.instance.discardActiveObject();

			const { leftPos, topPos } = this.getNextObjectPosition();
			clonedObj.set({ left: leftPos, top: topPos });

			if (clonedObj.type === 'activeSelection')
			{
				clonedObj.canvas = canvas.instance;
				clonedObj.forEachObject((obj) => this.addObjectToCanvas(obj));
				clonedObj.setCoords();
			} else
			{
				this.addObjectToCanvas(clonedObj);
			}

			canvas.instance.setActiveObject(clonedObj);
			canvas.instance.requestRenderAll();
		});
	}

	getNextObjectPosition()
	{
		// Calculate visible area
		const zoom = canvas.instance.getZoom();
		const pan = canvas.instance.viewportTransform;
		const widthVisible = canvas.instance.getWidth() / zoom;
		const heightVisible = canvas.instance.getHeight() / zoom;
		const leftVisible = -pan[4] / zoom;
		const topVisible = -pan[5] / zoom;

		// Determine center of visible area
		let leftPos = leftVisible + widthVisible / 2;
		let topPos = topVisible + heightVisible / 2;

		// Check if the viewport has changed
		if (this.lastPastePosition &&
			this.lastPastePosition.leftPos === leftPos &&
			this.lastPastePosition.topPos === topPos
		)
		{
			leftPos += 10 * this.pasteCount;
			topPos += 10 * this.pasteCount;
			this.pasteCount++;
		}
		else
		{
			// Viewport has changed, reset count and record the new position
			this.pasteCount = 1;
			this.lastPastePosition = { leftPos, topPos };
		}

		return { leftPos, topPos };
	}

	canAddObjects(numberOfObjectsToAdd)
	{
		const currentObjects = this.getSelectableObjects().length;
		const totalObjectsAfterAdd = currentObjects + numberOfObjectsToAdd;

		if (totalObjectsAfterAdd > this.maxObjects)
		{
			alert(`Adding ${numberOfObjectsToAdd} object(s) would exceed the limit. There are currently ${currentObjects} object(s) out of maximum ${this.maxObjects}`);
			return false;
		}
		console.log(`Objects: ${totalObjectsAfterAdd} (${currentObjects} + ${numberOfObjectsToAdd})`);
		return true;
	}

	getNumObjects(object)
	{
		if (object.type === 'activeSelection')
		{
			return object.getObjects().length;
		} else
		{
			return 1;
		}
	}

	addObjectToCanvas(obj)
	{
		obj.set({ id: stateManager.instance.generateRandomId() });
		setTextControlsSettings(obj);
		canvas.instance.add(obj);
	}

	deleteObject()
	{
		const activeObjects = canvas.instance.getActiveObjects();
		if (!activeObjects.length) return;

		canvas.instance.remove(...activeObjects);
		canvas.instance.discardActiveObject().requestRenderAll();
	}

	tabObject(reverse = false)
	{
		const objects = this.getSelectableObjects();
		if (!objects.length) return;

		const activeObject = canvas.instance.getActiveObject();
		const currentIndex = activeObject && !activeObject.excludeFromExport
			? objects.indexOf(activeObject)
			: -1;

		const nextIndex = reverse
			? (currentIndex - 1 + objects.length) % objects.length
			: (currentIndex + 1) % objects.length;

		canvas.instance.setActiveObject(objects[nextIndex]);
		canvas.instance.renderAll();
	}

	selectAllObjects()
	{
		// Get filtered objects
		const objects = this.getSelectableObjects();

		if (!objects.length) return;

		// Deselect currently active objects
		canvas.instance.discardActiveObject();

		const activeSelection = new fabric.ActiveSelection(objects, {
			canvas: canvas.instance,
		});
		canvas.instance.setActiveObject(activeSelection);
		canvas.instance.requestRenderAll();
	}

	clearObjects()
	{
		canvas.instance.discardActiveObject();

		this.getSelectableObjects().forEach((obj) => canvas.instance.remove(obj));

		canvas.instance.requestRenderAll();
	}

	getSelectableObjects()
	{
		return canvas.instance.getObjects().filter(object => !object.excludeFromExport);
	}
}