import { fabric } from 'fabric';
import { canvas } from '../../autoload';

class Pentagon
{
	constructor()
	{
		this.pentagon = new fabric.Polygon([
			{ x: 50, y: 0 },
			{ x: 100, y: 38 },
			{ x: 82, y: 100 },
			{ x: 18, y: 100 },
			{ x: 0, y: 38 }], {
			left: canvas.instance.width / 2,
			top: canvas.instance.height / 2,
			fill: '#D9D9D9',
		});
	}

	getObject()
	{
		return this.pentagon;
	}
}

export default Pentagon;
