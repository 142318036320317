// ScaleGuidelines.js

export class ScaleGuidelines
{
	ctx;
	canvas;

	constructor({ canvas })
	{
		this.canvas = canvas;
		this.ctx = this.canvas.getSelectionContext();

		// Guidelines
		this.scaleSnapThreshold = 4;
		this.lineWidth = 0.5;
		this.signSize = 4;
		this.lineColor = "#F68066";

		this.scaleSnapGuideX = null;
		this.scaleSnapGuideY = null;

		// Handles
		this.cornerHandles = ['tr', 'tl', 'br', 'bl'];
		this.topCornerHandles = ['tl', 'tr'];
		this.bottomCornerHandles = ['bl', 'br'];

		this.xCorners = ['mr', 'ml', 'tr', 'tl', 'br', 'bl'];
		this.yCorners = ['mt', 'mb', 'tr', 'tl', 'br', 'bl'];

		this.topHandles = ['mt', 'tl', 'tr'];
		this.rightHandles = ['mr', 'tr', 'br'];
		this.bottomHandles = ['mb', 'bl', 'br'];
		this.leftHandles = ['ml', 'tl', 'bl'];

		this.init();
	}

	init()
	{
		this.canvas.on('object:scaling', (e) => this.handleObjectScaling(e));
		this.canvas.on('mouse:up:before', (e) => this.cleanSnapGuidelines(e));

		// Before the rendering, clear previous drawings
		this.canvas.on('before:render', () => this.clearGuideline());

		// After the rendering, redraw the guidelines
		this.canvas.on('after:render', () =>
		{
			if (this.scaleSnapGuideX)
			{
				this.createGuidelines(this.scaleSnapGuideX, this.scaleSnapGuideX);
			}

			if (this.scaleSnapGuideY)
			{
				this.createGuidelines(this.scaleSnapGuideY, this.scaleSnapGuideY);
			}
		});

		//always ontop
		this.canvas.calcOffset();
	}

	cleanSnapGuidelines()
	{
		if (this.scaleSnapGuideX)
		{
			this.clearGuideline();
			this.scaleSnapGuideX = null;
		}
		if (this.scaleSnapGuideY)
		{
			this.clearGuideline();
			this.scaleSnapGuideY = null;
		}
	}

	handleObjectScaling(e)
	{
		// Keys
		const altKey = e.e.altKey;
		const shiftKey = e.e.shiftKey;

		// Object
		const obj = e.target;
		const oldScaleX = obj.scaleX;
		const oldScaleY = obj.scaleY;
		const left = obj.left - obj.width * obj.scaleX / 2 - obj.strokeWidth;
		const top = obj.top - obj.height * obj.scaleY / 2 - obj.strokeWidth;
		const right = obj.left + obj.width * obj.scaleX / 2 + obj.strokeWidth;
		const bottom = obj.top + obj.height * obj.scaleY / 2 - obj.strokeWidth;
		const corner = e.transform.corner;

		// Snap handling for different corners
		const canSnapBoth = this.cornerHandles.includes(corner);
		const canSnapX = this.xCorners.includes(corner);
		const canSnapY = this.yCorners.includes(corner);

		const objects = this.canvas.getObjects().filter(obj => !obj.ignoreEvents);

		// Remove old guidelines
		this.cleanSnapGuidelines();

		// Disable if altkey being used (not supported))
		if (altKey || (altKey && shiftKey)) { return; }

		// Disable if selected object is rotated (not supported)
		if (obj.angle !== 0) { return };

		for (let i = 0; i < objects.length; i++)
		{
			// track if we snapped to a line and the distance
			const snapped = {
				x: { snap: false, distance: this.scaleSnapThreshold + obj.strokeWidth },
				y: { snap: false, distance: this.scaleSnapThreshold + obj.strokeWidth }
			};
			const target = objects[i];

			// Don't snap to self
			if (obj == objects[i]) { continue; }

			// Don't snap to rotated objects (not supported)
			if (target.angle != 0) { continue; }

			// console.log("scaleX:", obj.scaleX, "flipX:", obj.flipX);
			// console.log("scaleX:", obj.scaleX, "flipX:", obj.flipY);

			// Calculate distances for snapping points
			this.calculateSnapDistances(target, snapped, corner, top, bottom, left, right);

			// If shift key is pressed, only snap to one axis
			if (!shiftKey && snapped.x.snap && snapped.y.snap && canSnapBoth)
			{
				// Both X and Y are in snap range, prioritize the closest one
				if (snapped.x.distance < snapped.y.distance)
				{
					snapped.y.snap = false;
				}
				else if (snapped.y.distance < snapped.x.distance)
				{
					snapped.x.snap = false;
				}
			}

			// Snap X
			if (snapped.x.snap && canSnapX)
			{
				if ((this.rightHandles.includes(corner)) ^ (obj.scaleX < 0))
				{
					obj.scaleX = (snapped.x.snapSize - obj.left + obj.width * Math.abs(obj.scaleX) / 2 - obj.strokeWidth / 2) / obj.width * (obj.scaleX < 0 ? -1 : 1);
					obj.left = snapped.x.snapSize - obj.width * Math.abs(obj.scaleX) / 2 - obj.strokeWidth / 2;
				}
				else if ((this.leftHandles.includes(corner)) ^ (obj.scaleX < 0))
				{
					obj.scaleX = (obj.left + obj.width * Math.abs(obj.scaleX) / 2 - snapped.x.snapSize - obj.strokeWidth / 2) / obj.width * (obj.scaleX < 0 ? -1 : 1);
					obj.left = snapped.x.snapSize + obj.width * Math.abs(obj.scaleX) / 2 + obj.strokeWidth / 2;
				}

				// Calc Guidelines
				this.scaleSnapGuideY = this.calculateGuidelines(corner, snapped.x.snapSize, target.aCoords, { top: top, bottom: bottom });
			}

			// Snap Y
			if (snapped.y.snap && canSnapY)
			{
				if ((this.bottomHandles.includes(corner)) ^ (obj.scaleY < 0))
				{
					obj.scaleY = (snapped.y.snapSize - obj.top + obj.height * Math.abs(obj.scaleY) / 2 - obj.strokeWidth / 2) / obj.height * (obj.scaleY < 0 ? -1 : 1);
					obj.top = snapped.y.snapSize - obj.height * Math.abs(obj.scaleY) / 2 - obj.strokeWidth / 2;
				}
				else if ((this.topHandles.includes(corner)) ^ (obj.scaleY < 0))
				{
					obj.scaleY = (obj.top + obj.height * Math.abs(obj.scaleY) / 2 - snapped.y.snapSize - obj.strokeWidth / 2) / obj.height * (obj.scaleY < 0 ? -1 : 1);
					obj.top = snapped.y.snapSize + obj.height * Math.abs(obj.scaleY) / 2 + obj.strokeWidth / 2;
				}

				// Calc Guidelines
				this.scaleSnapGuideX = this.calculateGuidelines(corner, snapped.y.snapSize, target.aCoords, { left: left, right: right });
			}

			// Maintain the aspect ratio with the updated snapping code
			if (!shiftKey && (this.cornerHandles.includes(corner)))
			{
				this.maintainAspectRatio(corner, oldScaleX, oldScaleY, snapped);
			}

			if (snapped.x.snap || snapped.y.snap) { break; }
		}
	}

	calculateGuidelines(corner, snapSize, targetCoords, objectCoords)
	{
		if (this.xCorners.includes(corner))
		{
			const minY = Math.min(targetCoords.tl.y, objectCoords.top);
			const maxY = Math.max(targetCoords.bl.y, objectCoords.bottom);
			return { x1: snapSize, y1: minY, x2: snapSize, y2: maxY };
		}

		if (this.yCorners.includes(corner))
		{
			const minX = Math.min(targetCoords.tl.x, objectCoords.left);
			const maxX = Math.max(targetCoords.tr.x, objectCoords.right);
			return { x1: minX, y1: snapSize, x2: maxX, y2: snapSize };
		}
	}

	calculateSnapDistances(target, snapped, corner, top, bottom, left, right)
	{
		const snapPointsX = [target.aCoords.tl.x, target.aCoords.tr.x];
		const snapPointsY = [target.aCoords.bl.y, target.aCoords.tl.y];

		snapPointsX.forEach((snapSize) =>
		{
			const distanceToSnapLine = (this.rightHandles.includes(corner)) ?
				Math.abs(right - snapSize) :
				Math.abs(left - snapSize)
				;
			if (distanceToSnapLine < snapped.x.distance)
			{
				snapped.x = { snap: true, distance: distanceToSnapLine, snapSize: snapSize };
			}
		});
		snapPointsY.forEach((snapSize) =>
		{
			const distanceToSnapLine = (this.bottomHandles.includes(corner)) ?
				Math.abs(bottom - snapSize) :
				Math.abs(top - snapSize)
				;
			if (distanceToSnapLine < snapped.y.distance)
			{
				snapped.y = { snap: true, distance: distanceToSnapLine, snapSize: snapSize };
			}
		});
	}

	maintainAspectRatio(corner, oldScaleX, oldScaleY, snapped)
	{
		const obj = this.canvas.getActiveObject();
		const ratio = obj.width * oldScaleX / (obj.height * oldScaleY);
		const scales = {
			x: () =>
			{
				obj.scaleY = obj.scaleX / ratio;
				const topModifier = ((this.topCornerHandles.includes(corner) && !obj.flipY) || (this.bottomCornerHandles.includes(corner) && obj.flipY)) ? 1 : -1;
				obj.top += topModifier * ((obj.height * oldScaleY - obj.height * obj.scaleY) / 2);
			},
			y: () =>
			{
				obj.scaleX = obj.scaleY * ratio;
				const leftModifier = ((this.rightHandles.includes(corner) && !obj.flipX) || (this.leftHandles.includes(corner) && obj.flipX)) ? -1 : 1;
				obj.left += leftModifier * ((obj.width * oldScaleX - obj.width * obj.scaleX) / 2);
			}
		}

		if (snapped.x.snap) scales.x();
		if (snapped.y.snap) scales.y();
	}

	createGuidelines(xCoords, yCoords)
	{
		const { x1, y1, x2, y2 } = xCoords;
		const { x3, y3, x4, y4 } = yCoords;

		// Draw guideline
		this.drawLine(x1, y1, x2, y2);
		// this.drawLine(x3, y3, x4, y4);

		// Draw signs
		this.drawSign(x1, y1, this.signSize);
		this.drawSign(x2, y2, this.signSize);
		this.drawSign(x3, y3, this.signSize);
		this.drawSign(x4, y4, this.signSize);
	}

	drawLine(x1, y1, x2, y2)
	{
		const ctx = this.ctx;

		ctx.save();
		ctx.lineWidth = this.lineWidth;
		ctx.strokeStyle = this.lineColor;
		ctx.beginPath();

		// Transform the points according to the viewport's transformation
		const p1 = fabric.util.transformPoint(new fabric.Point(x1, y1), this.canvas.viewportTransform);
		const p2 = fabric.util.transformPoint(new fabric.Point(x2, y2), this.canvas.viewportTransform);

		ctx.moveTo(p1.x, p1.y);
		ctx.lineTo(p2.x, p2.y);

		ctx.stroke();
		ctx.restore();
	}

	drawSign(x, y, size)
	{
		const ctx = this.ctx;

		ctx.save();
		ctx.lineWidth = this.lineWidth;
		ctx.strokeStyle = this.lineColor;
		ctx.beginPath();

		const p1 = fabric.util.transformPoint(new fabric.Point(x - size, y - size), this.canvas.viewportTransform);
		const p2 = fabric.util.transformPoint(new fabric.Point(x + size, y + size), this.canvas.viewportTransform);
		const p3 = fabric.util.transformPoint(new fabric.Point(x + size, y - size), this.canvas.viewportTransform);
		const p4 = fabric.util.transformPoint(new fabric.Point(x - size, y + size), this.canvas.viewportTransform);

		// Draw first line of the cross
		ctx.moveTo(p1.x, p1.y);
		ctx.lineTo(p2.x, p2.y);

		// Draw second line of the cross
		ctx.moveTo(p3.x, p3.y);
		ctx.lineTo(p4.x, p4.y);

		ctx.stroke();
		ctx.restore();
	}

	clearGuideline()
	{
		this.canvas.clearContext(this.ctx);
	}
}