// /modules/objects/Image.js

import { fabric } from 'fabric';
import { canvas } from '../../autoload';

class Image
{
	constructor({ url, callback })
	{
		this.imageLoadPromise = new Promise((resolve) =>
		{
			fabric.Image.fromURL(url, img => 
			{
				img.set({
					left: canvas.instance.width / 2,
					top: canvas.instance.height / 2
				});
				img.getElement().crossOrigin = 'anonymous';
				this.img = img;
				resolve(this.img);  // Resolve the promise with the image object
				if (callback)
				{
					callback(img);
				}
			}, { crossOrigin: 'Anonymous' });
		});
	}

	getObject()
	{
		return this.imageLoadPromise;
	}
}

export default Image;