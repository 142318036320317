// PanningManager.js
import
{
	canvas
} from '../autoload';

/**
 * Manages the panning behaviour on a canvas from Fabric.js library. 
 * User can pan around the canvas by holding down the spacebar and dragging the mouse.
 * Panning is paused on mouse release or spacebar release.
 * @class
 */
export class PanningManager
{
	/**
	 * The canvas should have properties like 'on' for event listening and 'viewportTransform' for changing the view.
	 */
	constructor()
	{
		this.isSpaceDown = false;
		this.isMouseDown = false;
		this.lastPosX = 0;
		this.lastPosY = 0;
	}

	/**
	 * Initializes the panning functionality on the canvas. Should be called during setup.
	 */
	initialize()
	{
		canvas.instance.on('mouse:down', (opt) => this.handleMouseDown(opt));
		canvas.instance.on('mouse:move', (opt) => this.handleMouseMove(opt));
		canvas.instance.on('mouse:up', (opt) => this.handleMouseUp(opt));
		canvas.instance.on('mouse:wheel', (opt) => this.handleMouseWheel(opt));
		document.addEventListener('keydown', (event) => this.handleKeydown(event));
		document.addEventListener('keyup', (event) => this.handleKeyup(event));
		document.addEventListener('mousemove', (event) => this.handleDocMouseMove(event));
		document.addEventListener('mousedown', (event) => this.handleMidMouseDown(event));
		document.addEventListener('mouseup', (event) => this.handleMidMouseUp(event));
	}

	/**
	 * Mouse down handler for the canvas. Begins the pan if spacebar is held down.
	 * Disables canvas selection and begins the pan action.
	 *
	 * @param {Object} opt - The mouse event details.
	 * @event mouse:down
	 */
	handleMouseDown(opt)
	{
		if (this.isSpaceDown && opt.e.buttons === 1)
		{
			this.performMouseDownActivities(opt.e);
		}
	}

	/**
	 * Mouse move handler for the canvas. If in panning mode, moves the point of view based on how much mouse moved.
	 *
	 * @param {Object} opt - The mouse event details.
	 * @event mouse:move
	 */
	handleMouseMove(opt)
	{
		if (this.isDragging)
		{
			const evt = opt.e;
			const deltaX = evt.clientX - this.lastPosX, deltaY = evt.clientY - this.lastPosY;

			canvas.instance.viewportTransform[4] += deltaX;
			canvas.instance.viewportTransform[5] += deltaY;

			canvas.instance.requestRenderAll();

			this.lastPosX = evt.clientX;
			this.lastPosY = evt.clientY;
		}
	}

	/**
	 * Mouse up handler for the canvas. Stops panning action and updates the final view.
	 *
	 * @param {Object} opt - The mouse event details.
	 * @event mouse:up
	 */
	handleMouseUp(opt)
	{
		if (this.isMouseDown && opt.e.button === 0)
		{
			this.performMouseUpActivities();
		}
	}

	/**
	 * Mouse wheel handler for the canvas. Allows for panning with trackpad or mouse wheel.
	 *
	 * @param {Object} opt - The wheel event details.
	 * @event wheel
	 */
	handleMouseWheel(opt)
	{
		const evt = opt.e;

		// Apply additional condition for ctrl key not being pressed (allowing for trackpad pan without ctrl or space)
		if (!evt.ctrlKey && (evt.deltaX !== 0 || evt.deltaY !== 0)) 
		{
			opt.e.preventDefault();
			opt.e.stopPropagation();

			canvas.instance.viewportTransform[4] += -evt.deltaX;
			canvas.instance.viewportTransform[5] += -evt.deltaY;

			canvas.instance.requestRenderAll();
		}
	}

	/**
	 * Key down handler for the entire document. Activates panning mode when spacebar is pressed down. 
	 * Changes cursor to 'grab' when spacebar pressed.
	 *
	 * @param {Object} event - The keydown event details.
	 * @event keydown
	 */
	handleKeydown(event)
	{
		if (event.code === 'Space')
		{
			this.isSpaceDown = true;

			// Prevent object selection when panning
			// canvas.forEachObject(function (object)
			// {
			// 	object.selectable = false;
			// });

			if (!this.isMouseDown)
			{
				canvas.instance.setCursor('grab');
			}
		}
	}

	/**
	 * Key up handler for the entire document. Deactivates panning mode when spacebar is released. 
	 * Changes cursor back to 'default' when spacebar released.
	 *
	 * @param {Object} event - The keyup event details.
	 * @event keyup
	 */
	handleKeyup(event)
	{
		if (event.code === 'Space')
		{
			this.isSpaceDown = false;

			// Re-Enable Object Selection
			// canvas.forEachObject(function (object)
			// {
			// 	object.selectable = true;
			// });

			canvas.instance.setCursor(this.isMouseDown ? 'grabbing' : 'default');
		}
	}

	/**
	 * Mouse move handler for the entire document. Changes cursor to 'grab' if in panning mode and not dragging.
	 * 
	 * @param {Object} event - The mouse move event details.
	 * @event mouse:move
	 */
	handleDocMouseMove()
	{
		if (this.isSpaceDown && !this.isMouseDown)
		{
			canvas.instance.setCursor('grab');
		}
	}

	/**
	 * Mouse middle down handler for the entire document. Activates panning mode when the middle mouse button is pressed. 
	 * Changes cursor to 'grabbing' when middle mouse button is pressed.
	 *
	 * @param {Object} event - The MouseDown event details.
	 * @event mousedown
	 */
	handleMidMouseDown(event)
	{
		if (event.button === 1)
		{
			event.preventDefault();

			this.isSpaceDown = true;

			this.performMouseDownActivities(event);
		}
	}

	/**
	 * Mouse middle up handler for the entire document. Deactivates panning mode when middle mouse button is released.
	 * Changes cursor back to 'default' when middle mouse button is released.
	 *
	 * @param {Object} event - The MouseUp event details.
	 * @event mouseup
	 */
	handleMidMouseUp(event)
	{
		if (event.button === 1)
		{
			this.isSpaceDown = false;

			this.performMouseUpActivities();
		}
	}

	/**
	 * Executes the common logic for mouse and middle mouse down events.
	 *
	 * @param {Object} event - The MouseDown event details.
	 */
	performMouseDownActivities(event)
	{
		this.isDragging = true;
		this.selection = false;

		this.lastPosX = event.clientX;
		this.lastPosY = event.clientY;

		this.isMouseDown = true;

		canvas.instance.setCursor('grabbing');
	}

	/**
	 * Executes the common logic for mouse and middle mouse up events.
	 */
	performMouseUpActivities()
	{
		canvas.instance.setViewportTransform(canvas.instance.viewportTransform);

		this.isDragging = false;
		this.selection = true;
		this.isMouseDown = false;

		canvas.instance.setCursor(this.isSpaceDown ? 'grabbing' : 'default');
	}
}