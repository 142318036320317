/**
 * Main entry point of the application. Initializes all necessary modules upon DOM load.
 * It imports necessary CSS styles and JavaScript modules, then initializes the canvas and toolbar once the DOM has loaded.
 * @module main
 */

// Import CSS
import './scss/styles.scss';

// Import Managers
import
{
	canvasManager,
	logManager,
	zoomManager,
	paperManager,
	toolbarManager,
	stateManager,
	objectFactory,
	objectOptions,
	textboxOptions,
	objectControls,
	panningManager,
	exportManager,
	keyboardShortcuts,
	selectionManager
} from './autoload';

import { CanvasManager } from './modules/CanvasManager';
import { LogManager } from './modules/LogManager';
import { ZoomManager } from './modules/ZoomManager';
import { PaperManager } from './modules/PaperManager';
import { ToolbarManager } from './modules/ToolbarManager';
import { StateManager } from './modules/StateManager';
import { ObjectFactory } from './modules/ObjectFactory';
import { ObjectOptions } from './modules/ObjectOptions';
import { TextboxOptions } from './modules/TextboxOptions';
import { ObjectControls } from './modules/ObjectControls';
import { PanningManager } from './modules/PanningManager';
import { ExportManager } from './modules/ExportManager';
import { SelectionManager } from './modules/SelectionManager';
import { KeyboardShortcuts } from './modules/KeyboardShortcuts';

document.addEventListener('DOMContentLoaded', function () 
{
	// Init Managers
	canvasManager.instance = new CanvasManager('fabric_canvas', 'canvas_parent');
	logManager.instance = new LogManager();
	zoomManager.instance = new ZoomManager();
	paperManager.instance = new PaperManager();
	toolbarManager.instance = new ToolbarManager();
	stateManager.instance = new StateManager();
	objectFactory.instance = new ObjectFactory();
	objectOptions.instance = new ObjectOptions();
	textboxOptions.instance = new TextboxOptions();
	objectControls.instance = new ObjectControls();
	panningManager.instance = new PanningManager();
	exportManager.instance = new ExportManager();
	selectionManager.instance = new SelectionManager();
	keyboardShortcuts.instance = new KeyboardShortcuts();

	// Initialize Classes
	canvasManager.instance.initializeCanvasEvents();
	zoomManager.instance.initialize();
	objectControls.instance.mouseOverControls();
	panningManager.instance.initialize();
	paperManager.instance.initialize();
	toolbarManager.instance.init();
});