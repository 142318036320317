// ToolbarManager.js

import
{
	exportManager,
	textboxOptions,
	objectFactory,
	objectOptions,
	stateManager,
	selectionManager,
	paperManager,
	zoomManager,
	canvas
} from '../autoload';

export class ToolbarManager
{
	constructor()
	{
		this.actions =
		{
			// Objects
			'changeLayer': (event) =>
			{
				const action = event.target.getAttribute('data-position');
				objectOptions.instance.changeLayer(action);
			},

			// Exports
			'saveCanvasToJPG': () => exportManager.instance.saveCanvasToJPG('jpeg', 0.8),
			'exportCanvasToJSON': () => exportManager.instance.exportCanvasToJSON('canvas.json'),
			'exportCanvasToSVG': () => exportManager.instance.exportCanvasToSVG('canvas.svg'),
			'loadCanvasFromJSON': () =>
			{
				const jsonInput = document.querySelector('[data-action="json-input"]');
				exportManager.instance.loadCanvasFromJSON(jsonInput.value);
			},

			// Zoom
			'zoomIn': () => zoomManager.instance.zoomIn(),
			'zoomOut': () => zoomManager.instance.zoomOut(),
			'zoom-to-actual': () => zoomManager.instance.zoomToActualSize(),
			'zoom-to-fit': () => zoomManager.instance.zoomToFit(),
		};

		this.toolbarEvents =
		{
			/* 
			* stateManager 
			*/
			'undo': { 'click': () => stateManager.instance.undo(), },
			'redo': { 'click': () => stateManager.instance.redo(), },
			'clearObjects': { 'click': () => stateManager.instance.saveStateTransaction(() => selectionManager.instance.clearObjects(), 'ToolbarClearObjects') },

			/* 
			* paperManager
			*/
			'setPaper': {
				'change': (event) => paperManager.instance.setPaperAndZoom(event.target.value),
				// this probably should save background rect into state?
			},

			/* 
			* objectFactory
			*/
			'addText': { 'click': () => objectFactory.instance.addText('Text...') },
			'addRect': { 'click': () => objectFactory.instance.addRect() },
			'addCircle': { 'click': () => objectFactory.instance.addCircle() },
			'addTriangle': { 'click': () => objectFactory.instance.addTriangle() },
			'addPentagon': { 'click': () => objectFactory.instance.addPentagon() },
			'addHexagon': { 'click': () => objectFactory.instance.addHexagon() },
			'addOctagon': { 'click': () => objectFactory.instance.addOctagon() },
			'addStar': { 'click': () => objectFactory.instance.addStar() },
			'addDiamond': { 'click': () => objectFactory.instance.addDiamond() },
			'addImage': { 'click': async () => { await objectFactory.instance.addImage('./assets/img/tucan.png'); }, },

			/* 
			* objectOptions
			*/
			'boldText': {
				'click': () => textboxOptions.instance.toggleTextStyle('fontWeight', 'bold'),
				callBack: (activeObject) =>
				{
					const isBold = textboxOptions.instance.getActiveStyle('fontWeight', activeObject) === 'bold';
					document.querySelector('[data-event="boldText"]').classList.toggle('active', isBold);
				}
			},
			'italicText': {
				'click': () => textboxOptions.instance.toggleTextStyle('fontStyle', 'italic')
			},
			'underlineText': {
				'click': () => textboxOptions.instance.toggleTextStyle('underline')
			},
			'corner-radius': // <input type="range">
			{
				'input': (event) => objectOptions.instance.setCornerRadius(parseInt(event.target.value, 10)),
				'change': () => stateManager.instance.saveState(),
				// callBack @todo: objectOptions.instance.getCornerRadius()
			},
			'fill-color': // <input type="color">
			{
				'input': (event) => textboxOptions.instance.setStyle('fill', event.target.value),
				'blur': () => stateManager.instance.saveState(),
				callBack: (activeObject) =>
				{
					const fillColor = textboxOptions.instance.getActiveStyle('fill', activeObject);
					document.querySelector('[data-event="fill-color"]').value = fillColor;
				}
			},
			'setTextAlignLeft': {
				'click': () => textboxOptions.instance.setTextAlign('left'),
				'blur': () => stateManager.instance.saveState(),
			},
			'setTextAlignCenter': {
				'click': () => textboxOptions.instance.setTextAlign('center'),
				'blur': () => stateManager.instance.saveState(),
			},
			'setTextAlignRight': {
				'click': () => textboxOptions.instance.setTextAlign('right'),
				'blur': () => stateManager.instance.saveState(),
			},

			/* 
			* textboxOptions
			*/
			'object-opacity': // <input type="range">
			{
				'input': (event) => textboxOptions.instance.setStyle('opacity', event.target.value / 100),
				'change': () => stateManager.instance.saveState(),
				// callBack @todo: objectOptions.instance.getCornerRadius()
			},
			'highlight-color': // <input type="color">
			{
				'input': (event) => textboxOptions.instance.setStyle('textBackgroundColor', event.target.value),
				'blur': () => stateManager.instance.saveState(),
				callBack: (activeObject) =>
				{
					const highlightColor = textboxOptions.instance.getActiveStyle('textBackgroundColor', activeObject);

					if (highlightColor != undefined && highlightColor.length > 0)
						document.querySelector('[data-event="highlight-color"]').value = highlightColor;
				}
			},
			'font-family': // <select>
			{
				'input': (event) => textboxOptions.instance.setStyle('fontFamily', event.target.value),
				'change': () => stateManager.instance.saveState(),
				callBack: (activeObject) =>
				{
					const fontFamily = textboxOptions.instance.getActiveStyle('fontFamily', activeObject);
					document.querySelector('[data-event="font-family"]').value = fontFamily;
				}
			},
			'font-size': // <input type="number">
			{
				'input': (event) => textboxOptions.instance.setFontSize(parseInt(event.target.value, 10)),
				'change': () => stateManager.instance.saveState(),
				callBack: () => this.updateFontSizeInput(), // bind to Canvas Events
			},
		};
	}

	updateFontSizeInput()
	{
		const fontSize = textboxOptions.instance.getFontSizeFromActiveTextbox();
		document.querySelector('[data-event="font-size"]').value = fontSize;
	}

	// Toolbar undo/redo buttons not working right now
	// Move to onChange
	updateToolbarUndoRedoButtons()
	{
		const undoStackSize = stateManager.instance.undoStack.length;
		const redoStackSize = stateManager.instance.redoStack.length;
		document.querySelector('[data-event="undo"]').disabled = undoStackSize === 0;
		document.querySelector('[data-event="redo"]').disabled = redoStackSize === 0;

	}

	// V1: Simplify toolbarEvents by passing key+object
	// updateToolbarValue(toolbarKey, activeObject)
	// {
	// 	const selectedObject = objectOptions.instance.getActiveStyle(toolbarKey, activeObject);
	// 	document.querySelector(`[data-event=${toolbarKey}]`).value = selectedObject;
	// }

	// V2: Simplify toolbarEvents by passing key+object AND the manager class (makes each toolbar 1 line)
	// updateToolbarValue(getActiveStyleFunc, toolbarKey, activeObject)
	// {
	// 	const selectedObject = getActiveStyleFunc(toolbarKey, activeObject);
	// 	document.querySelector(`[data-event=${toolbarKey}]`).value = selectedObject;
	// }

	// Attach data-event to the relevant addEventListener
	attachEvents(toolbarKey)
	{
		document.querySelectorAll(`[data-event=${toolbarKey}]`).forEach((element) =>
		{
			for (let event in this.toolbarEvents[toolbarKey])
			{
				element.addEventListener(event, this.toolbarEvents[toolbarKey][event]);
			}
		});
	}

	initializeToolbarEvents()
	{
		for (let toolbarEvent in this.toolbarEvents)
		{
			this.attachEvents(toolbarEvent);
		}
	}

	init()
	{
		this.initializeToolbarEvents();

		// Attach event listeners
		document.querySelectorAll('[data-action]').forEach((element) =>
		{
			element.addEventListener('click', (event) =>
			{
				const action = element.getAttribute('data-action');
				if (this.actions[action])
				{
					this.actions[action](event);
				}
			});
		});

		document.getElementById('zoomSlider').oninput = (v) =>
		{
			const zoomFactor = v.target.value / 100;

			canvas.instance.zoomToPoint(new fabric.Point(canvas.instance.width / 2, canvas.instance.height / 2), zoomFactor);
			const label_elem = document.getElementById('zoomAmount');
			label_elem.value = Math.floor(v.target.value) + '%';
		}

		// Canvas event listeners
		canvas.instance.on('object:scaling', () =>
		{
			this.updateFontSizeInput();
		});

		canvas.instance.on('selection:created', () =>
		{
			this.updateToolbar();
			this.updateFontSizeInput();
		});

		canvas.instance.on('selection:updated', () =>
		{
			this.updateToolbar();
			this.updateFontSizeInput();
		});

		canvas.instance.on('before:selection:cleared', () =>
		{
			document.getElementById('toolbar').style.display = 'none';
		});
	}

	updateToolbar()
	{
		const activeObject = canvas.instance.getActiveObject();

		if (!activeObject)
		{
			document.getElementById('toolbar').style.display = 'none';
			return;
		}

		// Show/Hide . edit_text & .edit_object
		if (activeObject.type === 'textbox')
		{
			document.querySelectorAll('.edit_object').forEach(el => el.style.display = 'none');
			document.querySelectorAll('.edit_text').forEach(el => el.style.display = 'inline');
		}
		else
		{
			document.querySelectorAll('.edit_text').forEach(el => el.style.display = 'none');
			document.querySelectorAll('.edit_object').forEach(el => el.style.display = 'inline');
		}

		// 'update' Toolbar based on Objects
		for (let toolbarEvent in this.toolbarEvents)
		{
			if (this.toolbarEvents[toolbarEvent].callBack)
			{
				this.toolbarEvents[toolbarEvent].callBack(activeObject);
			}
		}

		document.getElementById('toolbar').style.display = 'inline-block';
	}

}