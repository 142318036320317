export class LogManager
{
	constructor()
	{
		this.logTypes = {
			// logStates: true,
			// logStateEvents: true,
		};
	}

	log(message, logType = '')
	{
		if (logType === '' || (logType && this.logTypes[logType]))
		{
			console.log(message);
		}
	}
}