/**
 * Set scale controls if the given object is textbox.
 *
 * @param {object} obj - The Fabric.js object to operate on.
 */
export const setTextControlsSettings = obj =>
{
    if (obj.type === 'textbox')
    {
        obj.setControlsVisibility({
            mt: false,
            mb: false,
            ml: true,
            mr: true,
            mtr: true
        });
    }
}