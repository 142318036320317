// RotateGuidelines.js

import
{
	canvas
} from '../autoload';

/**
 * RotateGuidelines class handles the snapping functionality in canvas operations. 
 * It allows objects to snap to certain angles during rotations.
 *
 * @class
 */
export class RotateGuidelines
{
	/** 
	 * Create a new SnappingManager instance.
	 */
	constructor()
	{
		this.rotateSnapThreshold = 5;
		this.init();
	}

	/**
	 * Initialize the SnappingManager by adding the necessary canvas event listeners.
	 */
	init()
	{
		canvas.instance.on('object:rotating', (e) => this.handleObjectRotating(e));
	}

	/**
	 * Handle object rotation and snapping on the canvas.
	 *
	 * @param {Object} e - The event object.
	 */
	handleObjectRotating(e) 
	{
		const obj = e.target;

		//Nearest 45 degree angle
		const closestAngle = Math.round(obj.angle / 45) * 45;

		//The rotation is within epsilon degrees
		if (Math.abs(closestAngle - obj.angle) < this.rotateSnapThreshold)
		{
			obj.angle = closestAngle
		}
	}
}