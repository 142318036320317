// ObjectFactory.js

// Modules
import
{
	stateManager,
	selectionManager,
	canvas
} from '../autoload';

// Utility
import { canvasConstants } from './utility/CanvasConstants';

// Objects
import Textbox from './objects/Textbox';
import Rectangle from './objects/Rectangle';
import Triangle from './objects/Triangle';
import Circle from './objects/Circle';
import Image from './objects/Image';
import Pentagon from './objects/Pentagon';
import Hexagon from './objects/Hexagon';
import Octagon from './objects/Octagon';
import Star from './objects/Star';
import Diamond from './objects/Diamond';

/**
 * Class representing a factory for creating objects
 * @class 
 */
export class ObjectFactory
{
	/**
	 * Method for creating an object of a specific class, repositioning it on the canvas, setting it as the active object, and firing an "object:modified" event. It also calls for the rendering of the canvas and the state to be saved. 
	 * @param {Object} Constructor - The class constructor for the object 
	 * @param {Object} params - The parameters to be passed to the constructor
	 * @returns {Object} The created object
	 * @event object:modified
	 */
	async createObject(Constructor, params)
	{
		if (!selectionManager.instance.canAddObjects(1)) return;

		const object = await new Constructor(params).getObject();

		const { leftPos, topPos } = selectionManager.instance.getNextObjectPosition();

		object.set({
			left: leftPos,
			top: topPos,
			originX: 'center',
			originY: 'center',
			strokeUniform: true,
			lockSkewingX: canvasConstants.LOCK_SKEWING_X,
			lockSkewingY: canvasConstants.LOCK_SKEWING_Y,
			id: stateManager.instance.generateRandomId(), // Unique Object ID
		});

		stateManager.instance.saveStateTransaction(() =>
		{
			canvas.instance.add(object);
			canvas.instance.setActiveObject(object);
		}, 'AddAndSetObject');

		canvas.instance.renderAll();

		return object;
	}

	/**
	 * Method for creating and returning a text object 
	 * @param {String} text - The text to be added
	 * @returns {Object} The created text object 
	 */
	addText(text)
	{
		return this.createObject(Textbox, text);
	}

	/**
	 * Method for creating and returning a rectangle object 
	 * @returns {Object} The created rectangle object 
	 */
	addRect()
	{
		return this.createObject(Rectangle);
	}

	/**
	 * Method for creating and returning a triangle object 
	 * @returns {Object} The created triangle object 
	 */
	addTriangle()
	{
		return this.createObject(Triangle);
	}

	/**
	 * Method for creating and returning a circle object 
	 * @returns {Object} The created circle object 
	 */
	addCircle()
	{
		return this.createObject(Circle);
	}

	/**
	 * Method for creating and returning an image object 
	 * @param {String} url - The URL of the image
	 * @param {Function} callback - The callback function to be passed to the image constructor
	 * @returns {Object} The created image object 
	 */
	addImage(url, callback)
	{
		return this.createObject(Image, { url, callback });
	}

	addPentagon()
	{
		return this.createObject(Pentagon);
	}

	addHexagon()
	{
		return this.createObject(Hexagon);
	}

	addOctagon()
	{
		return this.createObject(Octagon);
	}

	addStar()
	{
		return this.createObject(Star);
	}

	addDiamond()
	{
		return this.createObject(Diamond);
	}

	// addEllipse()
	// {
	// 	return this.createObject(Ellipse);
	// }

	// addPolygon()
	// {
	// 	return this.createObject(Polygon);
	// }

	// addLine()
	// {
	// 	return this.createObject(Line);
	// }

	// addPolyline()
	// {
	// 	return this.createObject(Polyline);
	// }

	// addPath()
	// {
	// 	return this.createObject(Path);
	// }
}