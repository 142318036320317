import { fabric } from 'fabric';
import { canvas } from '../../autoload';
class Octagon
{
	constructor()
	{
		this.octagon = new fabric.Polygon([
			{ x: 30, y: 0 },
			{ x: 70, y: 0 },
			{ x: 100, y: 30 },
			{ x: 100, y: 70 },
			{ x: 70, y: 100 },
			{ x: 30, y: 100 },
			{ x: 0, y: 70 },
			{ x: 0, y: 30 }], {
			left: canvas.instance.width / 2,
			top: canvas.instance.height / 2,
			fill: '#D9D9D9',
		});
	}

	getObject()
	{
		return this.octagon;
	}
}

export default Octagon;
