// textboxOptions.js

import
{
	stateManager,
	canvas
} from '../autoload';

export class TextboxOptions
{
	boldText()
	{
		this.toggleTextStyle('fontWeight', 'bold');
	}

	italicText()
	{
		this.toggleTextStyle('fontStyle', 'italic');
	}

	underlineText()
	{
		this.toggleTextStyle('underline');
	}

	setStyle(styleName, value)
	{
		this.setActiveStyle(styleName, value);
	}

	setFontSize(fontSize)
	{
		const activeObject = canvas.instance.getActiveObject();

		if (activeObject && activeObject.type === 'textbox')
		{
			const adjustedFontSize = fontSize / activeObject.scaleX;
			this.setStyle('fontSize', adjustedFontSize);
		}
	}

	setActiveStyle(styleName, value, object)
	{
		object = object || canvas.instance.getActiveObject();
		if (!object) { return; }

		if (object.type === 'textbox' && object.selectionStart !== object.selectionEnd)
		{
			// Apply style only to the selected text
			const style = {};
			style[styleName] = value;
			object.setSelectionStyles(style);
		}
		else
		{
			// Apply style to the entire object
			object.set(styleName, value);
		}

		object.setCoords();
		canvas.instance.renderAll();
	}

	setTextAlign(alignment)
	{
		const activeObject = canvas.instance.getActiveObject();
		if (activeObject && activeObject.type === 'textbox')
		{
			activeObject.set('textAlign', alignment);
			canvas.instance.renderAll();
		}
	}

	toggleTextStyle(styleName, styleValue)
	{
		const activeObject = canvas.instance.getActiveObject();
		if (activeObject && activeObject.type === 'textbox')
		{
			const isDecoration = ['underline', 'linethrough', 'overline'].includes(styleName);

			// Check if user is in editing mode
			if (activeObject.isEditing)
			{
				if (this.hasPartialSelection(activeObject))
				{
					this.toggleStyleForSelectedText(activeObject, styleName, styleValue, isDecoration);
				}
			} else
			{
				if (isDecoration)
				{
					activeObject.set(styleName, !activeObject[styleName]);
				} else
				{
					activeObject.set(styleName, activeObject[styleName] === styleValue ? 'normal' : styleValue);
				}
			}
			activeObject.setCoords();
			canvas.instance.renderAll();
		}
	}

	toggleStyleForEntireText(activeObject, styleName, styleValue, isDecoration)
	{
		for (let i = 0; i < activeObject.text.length; i++)
		{
			const currentStyle = activeObject.getStyleAtPosition(i);
			if (currentStyle[styleName])
			{ // only if individual style already exists
				const newStyleValue = isDecoration ? !currentStyle[styleName] : currentStyle[styleName] === styleValue ? 'normal' : styleValue;
				activeObject.setSelectionStyles({ [styleName]: newStyleValue }, i, i + 1);
			}
		}
	}

	hasPartialSelection(activeObject)
	{
		return activeObject.selectionStart !== activeObject.selectionEnd;
	}

	toggleStyleForSelectedText(activeObject, styleName, styleValue, isDecoration)
	{
		for (let i = activeObject.selectionStart; i < activeObject.selectionEnd; i++)
		{
			const currentStyle = activeObject.getStyleAtPosition(i);
			const newStyleValue = isDecoration ? !currentStyle[styleName] : currentStyle[styleName] === styleValue ? 'normal' : styleValue;
			activeObject.setSelectionStyles({ [styleName]: newStyleValue }, i, i + 1);
		}
	}

	toggleStyleForEntireText(activeObject, styleName, styleValue, isDecoration)
	{
		const currentStyle = activeObject.getStyleAtPosition(0);
		const newStyleValue = isDecoration ? !currentStyle[styleName] : currentStyle[styleName] === styleValue ? 'normal' : styleValue;
		for (let i = 0; i < activeObject._text.length; i++)
		{
			activeObject.setSelectionStyles({ [styleName]: newStyleValue }, i, i + 1);
		}
	}

	getActiveStyle(styleName, object)
	{
		object = object || canvas.instance.getActiveObject();
		if (!object) { return ''; }

		return object[styleName];
	}

	getActiveObjectStyles()
	{
		const activeObject = canvas.instance.getActiveObject();

		if (!activeObject)
		{
			return null;
		}

		// Returning required styles in an object
		return {
			fillColor: this.getActiveStyle('fill', activeObject),
			highlightColor: this.getActiveStyle('textBackgroundColor', activeObject),
			fontFamily: this.getActiveStyle('fontFamily', activeObject),
			type: activeObject.type
		};
	}

	getFontSizeFromActiveTextbox()
	{
		const activeObject = canvas.instance.getActiveObject();
		if (activeObject && activeObject.type === 'textbox')
		{
			let fontSize = this.getActiveStyle('fontSize', activeObject);
			fontSize *= activeObject.scaleX;
			return Math.round(fontSize);
		}
		return null;
	}
}