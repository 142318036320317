// ZoomManager.js

import { fabric } from 'fabric';
import
{
	canvas, paperManager
} from '../autoload';

/**
 * A manager to handle zooming-related operations for a canvas
 * @class
 */

export class ZoomManager
{
	/**
	 * Initialize the ZoomManager with default values
	 */
	constructor()
	{
		// Min/Max Magic Numbers
		this.zoomInIncrement = 1.1;
		this.zoomOutIncrement = 0.9;
		this.maxZoomIn = 2.5;
		this.maxZoomOut = 0.5;
		this.fitPaperToCanvas = 0.9;
	}

	/**
	 * Initialize the ZoomManager with the canvas's events
	 */
	initialize()
	{
		//Set control limits
		const zoomSlider = document.querySelector('[data-action="zoomSlider"]');
		zoomSlider.max = this.maxZoomIn * 100;
		zoomSlider.min = this.maxZoomOut * 100;
		zoomSlider.step = (this.zoomInIncrement - 1) * 100;

		canvas.instance.on('mouse:wheel', (opt) =>
		{
			if (opt.e.ctrlKey || opt.e.metaKey)
			{
				const zoomFactor = opt.e.deltaY < 0 ? this.zoomInIncrement : this.zoomOutIncrement;
				this.adjustZoomWithPoint(opt.e.offsetX, opt.e.offsetY, zoomFactor);

				opt.e.preventDefault();
				opt.e.stopPropagation();

				canvas.instance.renderAll();
				canvas.instance.calcOffset();
			}
		});
	}

	/**
	 * Decrease the zoom level incrementally
	 */
	zoomOut()
	{
		this.zoomIncrement(this.zoomOutIncrement);
	}

	/**
	 * Increase the zoom level incrementally
	 */
	zoomIn()
	{
		this.zoomIncrement(this.zoomInIncrement);
	}

	/**
	 * Setup a slider for zoom control
	 * @todo Move toolbar code out of here
	 */
	setSliderForZoom(defaultZoom = null)
	{
		const zoomSlider = document.querySelector('[data-action="zoomSlider"]');

		const zoom = canvas.instance.getZoom();
		const zoomProgress = zoom * 100;

		// Move to ToolbarManager
		zoomSlider.value = zoomProgress;

		// Move to ToolbarManager
		const zoomAmount = document.querySelector('[data-action="zoomAmount"]');
		zoomAmount.value = Math.floor(zoomProgress) + '%';
	}

	/** 
	 * Adjust zoom according to the zoom factor
	 * @param {number} zoom
	 * @param {number} zoomFactor
	 */
	adjustZoom(zoom, zoomFactor)
	{
		zoom = zoom * zoomFactor;
		if (zoom > this.maxZoomIn) { zoom = this.maxZoomIn }
		if (zoom < this.maxZoomOut) { zoom = this.maxZoomOut }
		canvas.instance.zoomToPoint(new fabric.Point(canvas.instance.width / 2, canvas.instance.height / 2), zoom);
		this.setSliderForZoom();
	}

	/** 
	 * Adjust zoom according to the zoom factor and zooms around a point
	 * @param {number} x - x position
	 * @param {number} y - y position
	 * @param {number} zoomFactor
	 */
	adjustZoomWithPoint(x, y, zoomFactor)
	{
		let zoom = canvas.instance.getZoom();
		zoom *= zoomFactor;

		// This part should be adjusted to respect your 200% or 2 as max zoom in level.
		if (zoom > this.maxZoomIn) { zoom = this.maxZoomIn }
		if (zoom < this.maxZoomOut) { zoom = this.maxZoomOut }

		canvas.instance.zoomToPoint(new fabric.Point(x, y), zoom);
		this.setSliderForZoom();
	}

	/**
	 * Apply the zoom increment
	 * @param {number} increment
	 */
	zoomIncrement(increment)
	{
		const newZoom = canvas.instance.getZoom() * increment;
		this.adjustZoom(newZoom, increment);
	}

	/**
	 * Zooms the canvas to its actual size.
	 */
	zoomToActualSize()
	{
		canvas.instance.zoomToPoint(new fabric.Point(canvas.instance.width / 2, canvas.instance.height / 2), 1);
		this.setSliderForZoom();
	}


	/**
	 * Zooms the canvas to show 100% of it's contens.
	 */
	zoomToFit()
	{
		this.setZoomToFitCanvasBackground(paperManager.instance.canvas_rect.width, paperManager.instance.canvas_rect.height);
	}


	/**
	 * Zooms in to the canvas.
	 */
	zoomInCanvas()
	{
		let zoom = canvas.instance.getZoom() + 0.1; // zoom factor +10%

		zoom = Math.min(zoom, this.maxZoomIn);
		canvas.instance.zoomToPoint(new fabric.Point(canvas.instance.width / 2, canvas.instance.height / 2), zoom);
		this.setSliderForZoom();
	}

	/**
	 * Zooms out of the canvas.
	 */
	zoomOutCanvas()
	{
		let zoom = canvas.instance.getZoom() - 0.1; // zoom factor -10%

		zoom = Math.max(zoom, this.maxZoomOut);
		canvas.instance.zoomToPoint(new fabric.Point(canvas.instance.width / 2, canvas.instance.height / 2), zoom);
		this.setSliderForZoom();
	}

	setZoomToFitCanvasBackground(width, height)
	{
		const zoomScaleFactor = Math.min(
			(canvas.instance.width * this.fitPaperToCanvas) / width,
			(canvas.instance.height * this.fitPaperToCanvas) / height
		);

		const centerPoint = new fabric.Point(canvas.instance.width / 2, canvas.instance.height / 2);
		canvas.instance.zoomToPoint(centerPoint, zoomScaleFactor);

		this.setSliderForZoom();
	}
}