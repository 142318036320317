// ObjectControls.js
// http://fabricjs.com/controls-customization

import
{
	canvas
} from '../autoload';

/**
 * Manages and customizes the behaviour of Fabric object controls on a canvas.
 * Allows custom appearance and interaction of object controls like color, size and more.
 * @class
 */
export class ObjectControls
{
	/**
	* Create an instance of ObjectControls.
	*/
	constructor()
	{
		// Control Styles
		this.controlStyles = {
			STYLE_FILL: '#FFFFFF',
			STYLE_BORDER: '#AAAAAA',
			STYLE_CONTAINER: '#2f8fd0',
		};

		// Custom Handles for Top/Bottom/Left/Right
		this.customControls = {
			CONTROL_STROKE: 1,
			CONTROL_WIDTH: 3, // Half the width
			CONTROL_LENGTH: 8, // Half the length
			CONTROL_CURVE: 12, // Curve point (must be larger than length)
		};

		this.setDefaultObjectProperties();
		this.setCustomControlHandles();
		this.setCustomRotateHandle();

		canvas.instance.on('object:moving', this.handleObjectMoving.bind(this));
		canvas.instance.on('object:scaling', this.handleObjectMoving.bind(this));
		canvas.instance.on('object:modified', this.handleObjectModified.bind(this));
	}

	/**
	 * Sets the default control properties for all Fabric objects.
	 */
	setDefaultObjectProperties()
	{
		const { STYLE_FILL, STYLE_CONTAINER, STYLE_BORDER } = this.controlStyles;

		fabric.Object.prototype.set({
			cornerColor: STYLE_FILL,
			borderColor: STYLE_CONTAINER,
			cornerStrokeColor: STYLE_BORDER,
			cornerSize: 12,
			cornerStyle: 'circle',
			transparentCorners: false,
			borderScaleFactor: 1,
			hoverCursor: 'default',
		});
	}

	/**
	 * Customizes control handles of Fabric objects, including top, bottom, left and right.
	 */
	setCustomControlHandles()
	{
		fabric.Object.prototype.controls.mt.render = this.drawControl(0);
		fabric.Object.prototype.controls.mb.render = this.drawControl(0);
		fabric.Object.prototype.controls.ml.render = this.drawControl(90);
		fabric.Object.prototype.controls.mr.render = this.drawControl(90);
	}

	/**
	 * Customizes rotate handle of Fabric objects.
	 */
	setCustomRotateHandle()
	{
		// https://codepen.io/MarsAndBack/pen/OJMdwLL
		// const renderCircle = fabric.controlsUtils.renderCircleControl;
		// fabric.Object.prototype.controls.mtr.render = renderCircle;
		fabric.Object.prototype.controls.mtr.y = 0.5;
		fabric.Object.prototype.controls.mtr.offsetY = 30;
	}

	/**
	 * Custom draw function to stylize control handles.
	 * @param {number} additionalRotation - Any additional rotation to be adjusted on the control.
	 * @returns {Function} - A function to draw the control on the canvas.
	 */
	drawControl = (additionalRotation) => (ctx, left, top, styleOverride, fabricObject) =>
	{
		const { STYLE_FILL, STYLE_BORDER } = this.controlStyles;
		const { CONTROL_STROKE, CONTROL_LENGTH, CONTROL_WIDTH, CONTROL_CURVE } = this.customControls;

		ctx.save();
		ctx.translate(left, top);
		ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle + additionalRotation));
		ctx.fillStyle = STYLE_FILL;
		ctx.strokeStyle = STYLE_BORDER;
		ctx.lineWidth = CONTROL_STROKE;
		ctx.beginPath();
		ctx.moveTo(-CONTROL_LENGTH, -CONTROL_WIDTH);
		ctx.lineTo(CONTROL_LENGTH, -CONTROL_WIDTH);
		ctx.quadraticCurveTo(CONTROL_CURVE, 0, CONTROL_LENGTH, CONTROL_WIDTH);
		ctx.lineTo(-CONTROL_LENGTH, CONTROL_WIDTH);
		ctx.quadraticCurveTo(-CONTROL_CURVE, 0, -CONTROL_LENGTH, -CONTROL_WIDTH);
		ctx.closePath();
		ctx.fill();
		ctx.stroke();
		ctx.restore();
	};

	/**
	 * Sets up the event listeners for interaction with object controls, including mouse over, mouse down and mouse out. 
	 */
	mouseOverControls()
	{
		canvas.instance.on('mouse:over', this.handleMouseOver.bind(this));
		canvas.instance.on('mouse:down', this.handleMouseDown.bind(this));
		canvas.instance.on('mouse:out', this.handleMouseOut.bind(this));
	}

	/**
	 * Handles the mouse over event, 
	 * checking for a target and that the target is not the active object on the canvas.
	 * @param {Object} options - The mouse event details.
	 * @event mouse:over
	 */
	handleMouseOver(options)
	{
		const target = options.target;
		if (!target) { return; }

		if (target !== canvas.instance.getActiveObject())
		{
			target._renderControls(canvas.instance.contextTop, { hasControls: false });
		}
	}

	/**
	 * Handles the mouse down events on the object controls.
	 * If a target object is clicked, clear the upper canvas context.
	 * @param {Object} options - The mouse event details.
		* @event mouse:down
	 */
	handleMouseDown(options)
	{
		const target = options.target;
		if (target)
		{
			target.set({ objectCaching: false });
			canvas.instance.clearContext(canvas.instance.contextTop);
		}
	}

	/**
	 * Handles the mouse out events on the object controls.
	 * If a targeted object is not the active object, clear the upper canvas context.
	 * @param {Object} options - The mouse event details.
		* @event mouse:out
	 */
	handleMouseOut(options)
	{
		const target = options.target;
		if (!target) { return; }

		if (target !== canvas.instance.getActiveObject())
		{
			canvas.instance.clearContext(canvas.instance.contextTop);
		}
	}

	/**
	 * Handles the object moving event, 
	 * checking for a target and that the target is not the active object on the canvas.
	 * @param {Object} options - The moving event details.
	 * @event object:moving
	 */
	handleObjectMoving(e)
	{
		e.target.set({
			hasControls: false,
			hoverCursor: 'default',
			borderColor: 'rgba(68, 154, 213, 0.5)',
		});
		e.target.dirty = true;
		canvas.instance.requestRenderAll();
	}

	/**
	 * Handles the object modified events on the object controls.
	 * If a targeted object is not the active object, clear the upper canvas context.
	 * @param {Object} options - The modified event details.
	 * @event object:modified
	 */
	handleObjectModified(e)
	{
		if (e.target._objects)
		{
			for (let i = 0; i < e.target._objects.length; i++)
			{
				e.target._objects[i].set({
					hasModified: true
				});
			}
		}
		else
		{
			e.target.set({
				hasModified: true
			});
		}

		e.target.set({
			hasControls: true,
			objectCaching: true,
			borderColor: 'rgba(68, 154, 213, 1)',
		});
		e.target.dirty = true;
		canvas.instance.requestRenderAll();
	}
}