// /modules/objects/Rectangle.js

import { fabric } from 'fabric';
import { canvas } from '../../autoload';

class Rectangle
{
	constructor()
	{
		this.rect = new fabric.Rect({
			left: canvas.instance.width / 2,
			top: canvas.instance.height / 2,
			fill: '#D9D9D9',
			stroke: '#000000',
			strokeWidth: 1,
			width: 200,
			height: 200,
			rx: 0, // corner-radius x
			ry: 0, // corner-radius y
			strokeUniform: true,
			centeredRotation: true
		});
	}

	getObject()
	{
		return this.rect;
	}
}

export default Rectangle;