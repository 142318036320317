// KeyboardShortcuts.js

import
{
	textboxOptions,
	selectionManager,
	zoomManager,
	stateManager,
	canvas
} from '../autoload';

/**
 * Handles keyboard shortcuts for different operations in the canvas.
 * @class
 * @requires CanvasManager
 * @requires ToolbarManager
 * @requires SelectionManager
 * @requires ZoomManager
 * @requires StateManager
 */
export class KeyboardShortcuts
{
	/**
	 * Sets the canvasManager and canvas, declares necessary fields for shortcuts, and binds context to methods.
	 */
	constructor()
	{
		this._clipboard;

		// Helper Functions
		this.onKeyDown = this.onKeyDown.bind(this);

		// Textbox Shortcuts
		this.textBoxShortcutsHandlers =
		{
			// toolbarManager
			'ctrl+b': () => { textboxOptions.instance.boldText(); },
			'ctrl+i': () => { textboxOptions.instance.italicText(); },
			'ctrl+u': () => { textboxOptions.instance.underlineText(); },
		};

		// Global Shortcuts
		this.globalShortcutsHandlers =
		{
			// selectionManager
			'ctrl+c': () => { selectionManager.instance.copyObject(); },
			'ctrl+x': () => { stateManager.instance.saveStateTransaction(() => selectionManager.instance.cutObject(), 'ShortcutCutObject'); },
			'ctrl+v': () => { stateManager.instance.saveStateTransaction(() => selectionManager.instance.pasteObject(), 'ShortcutPasteObject'); },
			'delete': () => { stateManager.instance.saveStateTransaction(() => selectionManager.instance.deleteObject(), 'ShortcutDeleteObject'); },
			'backspace': () => { stateManager.instance.saveStateTransaction(() => selectionManager.instance.deleteObject(), 'ShortcutBackspace'); },
			'ctrl+delete': () => { stateManager.instance.saveStateTransaction(() => selectionManager.instance.clearObjects(), 'ToolbarClearObjects'); },
			'ctrl+a': () => { stateManager.instance.saveStateTransaction(() => selectionManager.instance.selectAllObjects(), 'SelectAllObjects'); },
			'tab': () => { selectionManager.instance.tabObject(false); },
			'shift+tab': () => { selectionManager.instance.tabObject(true); },

			// toolbarManager
			'ctrl+z': () => { stateManager.instance.undo(); },
			'ctrl+shift+z': () => { stateManager.instance.redo(); },
			'ctrl+y': () => { stateManager.instance.redo(); },

			// zoomManager
			'ctrl++': () => { zoomManager.instance.zoomInCanvas(); },
			'ctrl+=': () => { zoomManager.instance.zoomInCanvas(); },
			'ctrl+-': () => { zoomManager.instance.zoomOutCanvas(); },
			'ctrl+0': () => { zoomManager.instance.zoomToFit(); },
			'ctrl+1': () => { zoomManager.instance.zoomToActualSize(); },
		};
	}

	/**
	 * Handles keyboard event and executes the corresponding operation function based on the input key combination.
	 * @param {KeyboardEvent} e - Keyboard event.
	 * @event
	 */
	onKeyDown(e)
	{
		// if (['INPUT', 'TEXTAREA', 'SELECT'].includes(document.activeElement.nodeName)) return;

		const isTextEditing = canvas.instance.getActiveObject()?.type === 'textbox' && canvas.instance.getActiveObject()?.isEditing;

		let keyCombination = '';
		if (e.ctrlKey || e.metaKey) { keyCombination += 'ctrl+'; }
		if (e.shiftKey) { keyCombination += 'shift+'; }
		keyCombination += e.key.toLowerCase();

		const textBoxShortcut = this.textBoxShortcutsHandlers[keyCombination];
		if (textBoxShortcut)
		{
			e.preventDefault();
			textBoxShortcut(e);
		}

		const globalShortcut = this.globalShortcutsHandlers[keyCombination];
		if (globalShortcut && !isTextEditing)
		{
			e.preventDefault();
			globalShortcut(e);
		}
	}
}