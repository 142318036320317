// PaperManager.js

import { fabric } from 'fabric';
import
{
	zoomManager,
	canvas
} from '../autoload';

/**
 * Handles the paper size and background rectangle.
 * @class
 */
export class PaperManager
{
	/**
	 * Initializes the canvas rectangle, adds it to the canvas, and sets the paper size and zoom.
	 */
	initialize()
	{
		this.createCanvasRect();
		this.addCanvasRect();
		this.setPaperAndZoom('21cm-29.7cm'); //a4
	}

	/**
	 * Creates the canvas rectangle and assigns a clipping mask.
	 */
	createCanvasRect()
	{
		const { width, height } = canvas.instance;

		this.canvas_rect = new fabric.Rect({
			left: width / 2,
			top: height / 2,
			fill: '#ffffff',
			originX: 'center',
			originY: 'center',
			stroke: '#d1d1d1',
			excludeFromExport: true,
			evented: false,
			selectable: false,
			shadow: {
				color: 'rgba(0,0,0,0.035)',
				offsetX: 0,
				offsetY: 6,
				blur: 30
			}
		});

		// Apply the clipping rectangle to the canvas context
		canvas.instance.clipTo = ctx => this.canvas_rect.render(ctx);
	}

	/**
	 * Adds the canvas rectangle to the canvas, sets the background color, and sends the rectangle to the back of the layer stack.
	 */
	addCanvasRect()
	{
		canvas.instance.setBackgroundColor('#f5f5f5');
		canvas.instance.add(this.canvas_rect);
		this.canvas_rect.sendToBack();
	}

	/** 
	 * Sets the dimensions of the canvas rectangle (the "paper") and adjusts the zoom level of the viewport.
	 * @param {string} option - A string representing the paper size (e.g., '21cm-29.7cm').
	 */
	setPaperAndZoom(option)
	{
		const [width, height] = option.split('-').map(size => fabric.util.parseUnit(size));

		this.canvas_rect.set({ width, height });
		canvas.instance.renderAll();

		zoomManager.instance.zoomToActualSize();
	}

	/**
	 * Returns the top, left, width and height of the canvas rectangle.
	 * @return {object} An object containing the top, left, width, and height of the canvas rectangle.
	 */
	getCanvasRectArea()
	{
		const { aCoords: { tl: { y: top, x: left } }, width, height, scaleX, scaleY } = this.canvas_rect;
		return { top, left, width: width * scaleX, height: height * scaleY };
	}
}