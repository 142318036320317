import { fabric } from 'fabric';
import { canvas } from '../../autoload';

class Star
{
	constructor()
	{
		const size = 200;
		this.star = new fabric.Polygon([
			{ x: size * 0.5, y: 0 },
			{ x: size * 0.61, y: size * 0.35 },
			{ x: size * 0.98, y: size * 0.35 },
			{ x: size * 0.68, y: size * 0.57 },
			{ x: size * 0.79, y: size * 0.91 },
			{ x: size * 0.5, y: size * 0.7 },
			{ x: size * 0.21, y: size * 0.91 },
			{ x: size * 0.32, y: size * 0.57 },
			{ x: size * 0.02, y: size * 0.35 },
			{ x: size * 0.39, y: size * 0.35 }], {
			left: canvas.instance.width / 2,
			top: canvas.instance.height / 2,
			fill: '#D9D9D9'
		});
	}

	getObject()
	{
		return this.star;
	}
}

export default Star;
