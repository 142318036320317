// StateManager.js

import
{
	logManager,
	toolbarManager,
	exportManager,
	selectionManager,
	canvas
} from '../autoload';

// Utility
import { canvasConstants } from './utility/CanvasConstants';

export class StateManager
{
	constructor()
	{
		this.state = {};
		this.undoStack = [];
		this.redoStack = [];
		this.stackMaxSize = 50;

		this.isSaveDeferred = false;
		this.pendingTransaction = false;
		this.externalPendingTransaction = false;

		this.bindCanvasEvents();

		this.logStates = 'logStates';
		this.logStateEvents = 'logStateEvents';
	}

	undo = () => { this.restoreState(this.undoStack, this.redoStack); };
	redo = () => { this.restoreState(this.redoStack, this.undoStack); };

	bindCanvasEvents()
	{
		const events = [
			'object:added',
			'object:removed',
			'object:modified',
			'selection:cleared',
			'selection:created',
			'selection:updated',
		];

		events.forEach(event =>
		{
			canvas.instance.on(event, () =>
			{
				this.saveObjectEvents(event);
			});
		});
	}

	saveObjectEvents(event)
	{
		if (this.isSaveDeferred || this.pendingTransaction || this.externalPendingTransaction) return;
		this.saveState(event);
	}

	saveState(eventName = '')
	{
		if (this.isSaveDeferred) return;

		this.state?.data && this.appendStateToStack(this.undoStack, this.state);
		this.redoStack = [];

		this.state = {
			data: JSON.stringify(canvas.instance.toJSON(canvasConstants.EXPORT_PROPERTIES)),
			selectedObjectId: selectionManager.instance.getActiveObjectId()
		};

		logManager.instance.log(this.logState(`Save: ` + eventName, this.undoStack), this.logStates);
		
		toolbarManager.instance.updateToolbarUndoRedoButtons();
	}

	saveStateTransaction(func, transactionName = '')
	{
		this.startTransaction(() =>
		{
			func();
			this.saveState(transactionName);
		});
	}

	appendStateToStack(stack, state)
	{
		stack.push(state);

		// Remove the oldest state if > max stack size
		if (stack.length > this.stackMaxSize)
		{
			stack.shift();
		}
	}

	startTransaction(transactionFunc)
	{
		this.pendingTransaction = true;
		transactionFunc();
		this.pendingTransaction = false;
	}

	restoreState(fromStack, toStack)
	{
		if (fromStack.length === 0) return;

		this.startTransaction(() =>
		{
			this.appendStateToStack(toStack, this.state);

			let tempState = fromStack.pop();

			if (tempState === this.state && fromStack.length)
				tempState = fromStack.pop();

			if (!tempState) return;

			this.state = tempState;

			exportManager.instance.loadCanvasFromJSON(this.state.data, false, () =>
			{
				toolbarManager.instance.updateToolbarUndoRedoButtons();
				selectionManager.instance.reselectObject(this.state.selectedObjectId);
				canvas.instance.requestRenderAll();
			});
		});

		logManager.instance.log(this.logState(fromStack === this.undoStack ? 'Undo' : 'Redo', fromStack,), this.logStates);
	}

	logState(action, stack)
	{
		const stackJSON = JSON.stringify(stack);
		const stateSize = stackJSON.length;
		const stateSizeInKB = (stateSize / 1024).toFixed(1);
		const currentStackSize = stack.length;
		const totalObjects = canvas.instance.getObjects().length;

		// console.log("Stack: ", stack); // inspect raw stack

		return `${action} ${currentStackSize} / ${this.stackMaxSize} (${stateSizeInKB}kb, ${totalObjects} objects)`;
	}

	// This should probably be moved somewhere else
	generateRandomId()
	{
		return 'id_' + Math.random().toString(36).substr(2, 9);
	}
}