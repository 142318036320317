// ObjectOptions.js
import
{
	paperManager,
	stateManager,
	canvas
} from '../autoload';

export class ObjectOptions
{
	changeLayer(action)
	{
		const activeObject = canvas.instance.getActiveObject();
		if (activeObject)
		{
			canvas.instance[action](activeObject);
			paperManager.instance.canvas_rect.sendToBack();
			canvas.instance.renderAll();
			stateManager.instance.saveState();
		}
	}

	setCornerRadius(cornerRadius)
	{
		const activeObject = canvas.instance.getActiveObject();

		if (activeObject && activeObject.type === 'rect')
		{
			activeObject.set('rx', cornerRadius);
			activeObject.set('ry', cornerRadius);
			canvas.instance.renderAll();
			// stateManager.instance.saveState();
		}
	}

	getActiveObjectCornerRadius()
	{
		const activeObject = canvas.instance.getActiveObject();
		if (activeObject && activeObject.type === 'rect')
		{
			return activeObject.get('rx');
		}
		return null;
	}
}